$(document).ready(function(){

  $('.m-menu').click(function(){
    if ($('body').hasClass('m-menu-open')){
      $('body').removeClass('m-menu-open');
    }else{
      $('body').addClass('m-menu-open');
    }
  });

  if($('body').hasClass('brand-design') || $('body').hasClass('pitch') || $('body').hasClass('brand-story')){
    var mainNavLinks = document.querySelectorAll(".sections a");
    var mainSections = document.querySelectorAll("section");

    var lastId;
    var cur = [];

    // https://css-tricks.com/sticky-smooth-active-nav/
    // This should probably be throttled.
    // Especially because it triggers during smooth scrolling.
    // https://lodash.com/docs/4.17.10#throttle
    // You could do like...
    // window.addEventListener("scroll", () => {
    //    _.throttle(doThatStuff, 100);
    // });
    // Only not doing it here to keep this Pen dependency-free.

    var currSection = '';
    var currSubSection = '';

    window.addEventListener("scroll", event => {
      var fromTop = window.scrollY + 50;

      if(fromTop > 150) {
        $('body').addClass('scrolling');
      }else{
        $('body').removeClass('scrolling');
      }
      mainNavLinks.forEach(link => {
        var section = document.querySelector(link.hash);

        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight >= (fromTop - 10)
        ) {
          link.classList.add("current");
          var newSection = $('.section-title.current').html();
          var newSubSection = $('.section-child.current').html()
          if(newSection != currSection){
            $('.m-submenu').html(newSection);
            currSection = newSection;
          }
          if(newSubSection != currSubSection && newSubSection != undefined){
            if(newSubSection != ''){
              $('.m-submenu').html(newSection + ' - ' + newSubSection);
            }else{
              $('.m-submenu').html(newSection);
            }

            currSubSection = newSubSection;
          }
        } else {
          link.classList.remove("current");
        }
        });
      });
    }

    // submenu
    var accordion_head = $('.sections > ul > li > a'),
        accordion_body = $('.sections li > .child-section');

    accordion_head.on('click', function(event) {

      if (!$(this).hasClass('current')){
        accordion_body.slideUp('normal');
      }

      // only slide if we have anchors to show (pitches don't always have titles)
      if($(this).parent().find('li').length > 0){
        $(this).next().stop(true,true).toggle(150,'easeInOutCubic');
      }
      accordion_head.removeClass('current');
      $(this).addClass('current');

    });

    $('.section-child').click(function(){
      $('body').removeClass('submenuopen');
    });


    $('.m-submenu').click(function(){
      if(!$('body').hasClass('submenuopen')){
        $('body').addClass('submenuopen');
      }else{
        $('body').removeClass('submenuopen');
      }
    })



    $("video").click(function() {
      if (this.paused) {
        $(this).parent().addClass('playing')
        this.play();
      } else {
        $(this).parent().removeClass('playing')
        this.pause();
      }
    });

    // Resize all iframes
    //*
    var $iframes = $( "iframe" );

    // Find & save the aspect ratio for all iframes
    $iframes.each(function () {
      $( this ).data( "ratio", this.height / this.width )
        // Remove the hardcoded width & height attributes
        .removeAttr( "width" )
        .removeAttr( "height" );
    });

    // Resize the iframes when the window is resized
    $( window ).resize( function () {
      $iframes.each( function() {
        var width = $( this ).parent().width();
        $( this ).width( width )
          .height( width * $( this ).data( "ratio" ) );
      });
    // Resize to fix all iframes on page load.
    }).resize();
    //*/
    // end resize iframes

});